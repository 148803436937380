import {createSelector} from "reselect";
import {getLocalState} from "../../../../shared/lib/localStorage/localStorage";


const selectAuthenticationSlice = (state: any) => state.authenticationReducer;


export const selectAuthenticationStatus = (state: any) => selectAuthenticationSlice(state)?.status;
export const selectAuthenticationAccessToken = (state: any) => selectAuthenticationSlice(state).accessToken;
export const selectAuthenticationEmail = (state: any) => selectAuthenticationSlice(state).email;
export const selectAuthenticationPassword = (state: any) => selectAuthenticationSlice(state).password;


export const selectUserIsExist = createSelector(selectAuthenticationAccessToken, (state) => {
    const user = getLocalState("user")

    if(!user?.accessToken && !state) return false;

    return true

})


export const selectUserCurrentRole = createSelector(selectAuthenticationAccessToken, (state) => {
    const splitValue = state.split('.')[1];

    if(!splitValue) {
        return "COMMON";
    }

    const decodeValue = JSON.parse(atob(splitValue))

    return decodeValue.role;
})
