// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PasswordInput_container__0VkFf {
  position: relative;
  width: 100%;
}

.PasswordInput_toggle__Sa2an {
  position: absolute;
  top: 50%;
  right: 17px;
  transform: translate(0%, -50%);
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/shared/outtalent-ui/PasswordInput/PasswordInput.module.scss"],"names":[],"mappings":"AAEA;EACI,kBAAA;EACA,WAAA;AADJ;;AAKA;EACI,kBAAA;EACA,QAAA;EACA,WAAA;EACA,8BAAA;EACA,eAAA;AAFJ","sourcesContent":["\n\n.container {\n    position: relative;\n    width: 100%;\n\n}\n\n.toggle {\n    position: absolute;\n    top: 50%;\n    right: 17px;\n    transform: translate(0%, -50%);\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `PasswordInput_container__0VkFf`,
	"toggle": `PasswordInput_toggle__Sa2an`
};
export default ___CSS_LOADER_EXPORT___;
