//@ts-ignore
import cls from "./Input.module.scss";
import {FC} from "react";
import {classNames} from "../../lib";

export const Input: FC<any> = ({ type, name, fullWidth = true, className, placeholder, register, ...props }) => {
    return (
        <input
            type={type}
            name={name}
            placeholder={placeholder}
            className={classNames(cls.input, {
                [cls.w100]: fullWidth,
            }, [className])}
            {...register}
            {...props}
        />
    )
}
