import {FC} from "react";
//@ts-ignore
import cls from "./ButtonLoader.module.scss";

export const ButtonLoader: FC<any> = ({ width = 19, height = 18, color }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                className={cls.fullRound}
                d="M18.5 9C18.5 13.9706 14.4706 18 9.5 18C4.52944 18 0.5 13.9706 0.5 9C0.5 4.02944 4.52944 0 9.5 0C14.4706 0 18.5 4.02944 18.5 9ZM2.759 9C2.759 12.723 5.77705 15.741 9.5 15.741C13.223 15.741 16.241 12.723 16.241 9C16.241 5.27705 13.223 2.259 9.5 2.259C5.77705 2.259 2.759 5.27705 2.759 9Z"
                fill="#3F46E4"
                fillOpacity="0.7"
            />
            <path
                className={cls.halfRound}
                d="M13.1778 0.785772C14.8025 1.51318 16.1749 2.706 17.1216 4.21341C18.0683 5.72082 18.5467 7.4751 18.4964 9.25442C18.4461 11.0337 17.8693 12.7582 16.8389 14.2097C15.8085 15.6612 14.3709 16.7745 12.7077 17.409L11.9026 15.2983C13.1483 14.8231 14.2251 13.9892 14.9968 12.902C15.7686 11.8149 16.2006 10.5233 16.2383 9.19056C16.276 7.85785 15.9176 6.54389 15.2086 5.41484C14.4995 4.2858 13.4715 3.39237 12.2547 2.84754L13.1778 0.785772Z"
                fill="white"
            />
        </svg>
    )
}
