import React, {useEffect, Suspense, Fragment} from 'react';
import {Payments} from "../entities/Payments/api/Payments";
import {Authentication} from "../entities/Authentication/api/Authentication";
import {Routes, Route, Navigate} from 'react-router-dom';
import {AuthenticationPage} from "../pages/AuthenticationPage/AuthenticationPage";
import {PaymentsPage} from "../pages/PaymentsPage/PaymentsPage";
import {GraphsPage} from "../pages/GraphsPage/GraphsPage";
import {ReactChartsPage} from "../pages/ReactChartsPage/ReactChartsPage";
import {ProtectedRoute} from "./layout/ProtectedRoute";
import {useSelector} from "react-redux";
import {selectUserIsExist} from "../entities/Authentication/model/selectors/authenticationSelectors";
import {BillingsPage} from "../pages/BillingsPage/BillingsPage";


function App() {
    const user = useSelector(selectUserIsExist);


  useEffect(() => {
    console.log(process.env.API_DJANGO_DOMAIN)

  }, []);

  return (
    <div>
      <Suspense fallback="">
        <Routes>
            {user ? (
                <Fragment>
                    <Route path="/payment/list" element={
                        <ProtectedRoute>
                            <PaymentsPage />
                        </ProtectedRoute>
                    } />
                    <Route path="/graphs" element={
                        <ProtectedRoute>
                            <GraphsPage />
                        </ProtectedRoute>
                    } />
                    <Route path="/billings" element={
                        <ProtectedRoute>
                            <BillingsPage />
                        </ProtectedRoute>
                    } />
                    {/*<Route*/}
                    {/*    path="/react-charts"*/}
                    {/*    element={*/}
                    {/*        <ProtectedRoute>*/}
                    {/*            <ReactChartsPage />*/}
                    {/*        </ProtectedRoute>*/}
                    {/*    }*/}
                    {/*/>*/}
                    <Route
                        path="*"
                        element={
                            <Navigate
                                to="/billings"
                                replace
                            />
                        }
                    />
                </Fragment>
            ) : (
                <Fragment>
                    <Route path="/sign-in" element={<AuthenticationPage />} />

                    <Route
                        path="*"
                        element={
                            <Navigate
                                to="/sign-in"
                                replace
                            />
                        }
                    />
                </Fragment>
            )}

        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
