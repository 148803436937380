import {FC} from "react";


export const CalendarIcon: FC<any> = ({ fill = "#3B3B3B" }) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.6">
                <path fillRule="evenodd" clipRule="evenodd" d="M1.16663 6.33366C1.16663 6.05752 1.39048 5.83366 1.66663 5.83366H14.3333C14.6094 5.83366 14.8333 6.05752 14.8333 6.33366V8.83366C14.8333 9.1098 14.6094 9.33366 14.3333 9.33366C14.0572 9.33366 13.8333 9.1098 13.8333 8.83366V6.83366H2.16663V13.667C2.16663 13.759 2.24124 13.8337 2.33329 13.8337H7.49996C7.7761 13.8337 7.99996 14.0575 7.99996 14.3337C7.99996 14.6098 7.7761 14.8337 7.49996 14.8337H2.33329C1.68897 14.8337 1.16663 14.3113 1.16663 13.667V6.33366Z" fill={fill} />
                <path fillRule="evenodd" clipRule="evenodd" d="M2.33329 3.16699C2.24125 3.16699 2.16663 3.24161 2.16663 3.33366V5.83366H13.8333V3.33366C13.8333 3.24161 13.7587 3.16699 13.6666 3.16699H2.33329ZM1.16663 3.33366C1.16663 2.68933 1.68896 2.16699 2.33329 2.16699H13.6666C14.311 2.16699 14.8333 2.68933 14.8333 3.33366V6.33366C14.8333 6.6098 14.6094 6.83366 14.3333 6.83366H1.66663C1.39048 6.83366 1.16663 6.6098 1.16663 6.33366V3.33366Z" fill={fill} />
                <path fillRule="evenodd" clipRule="evenodd" d="M5.33329 1.16699C5.60944 1.16699 5.83329 1.39085 5.83329 1.66699V4.33366C5.83329 4.6098 5.60944 4.83366 5.33329 4.83366C5.05715 4.83366 4.83329 4.6098 4.83329 4.33366V1.66699C4.83329 1.39085 5.05715 1.16699 5.33329 1.16699Z" fill={fill} />
                <path fillRule="evenodd" clipRule="evenodd" d="M10.6666 1.16699C10.9428 1.16699 11.1666 1.39085 11.1666 1.66699V4.33366C11.1666 4.6098 10.9428 4.83366 10.6666 4.83366C10.3905 4.83366 10.1666 4.6098 10.1666 4.33366V1.66699C10.1666 1.39085 10.3905 1.16699 10.6666 1.16699Z" fill={fill} />
                <path fillRule="evenodd" clipRule="evenodd" d="M11.0063 9.83366C9.80969 9.83366 8.83964 10.8037 8.83964 12.0003C8.83964 13.1969 9.80969 14.167 11.0063 14.167C12.2029 14.167 13.173 13.1969 13.173 12.0003C13.173 10.8037 12.2029 9.83366 11.0063 9.83366ZM7.83964 12.0003C7.83964 10.2514 9.2574 8.83366 11.0063 8.83366C12.7552 8.83366 14.173 10.2514 14.173 12.0003C14.173 13.7492 12.7552 15.167 11.0063 15.167C9.2574 15.167 7.83964 13.7492 7.83964 12.0003Z" fill={fill} />
                <path fillRule="evenodd" clipRule="evenodd" d="M10.673 10.5003C10.9491 10.5003 11.173 10.7242 11.173 11.0003V11.8337H12.0063C12.2824 11.8337 12.5063 12.0575 12.5063 12.3337C12.5063 12.6098 12.2824 12.8337 12.0063 12.8337H10.673C10.3968 12.8337 10.173 12.6098 10.173 12.3337V11.0003C10.173 10.7242 10.3968 10.5003 10.673 10.5003Z" fill={fill} />
            </g>
        </svg>
    )
}
