// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ButtonLoader_fullRound__iG5TG {
  fill: var(--main-color);
}

.ButtonLoader_halfRound__PAeYk {
  fill: var(--main-background-color);
}`, "",{"version":3,"sources":["webpack://./src/shared/icons/ButtonLoader/ButtonLoader.module.scss"],"names":[],"mappings":"AACA;EACI,uBAAA;AAAJ;;AAGA;EACI,kCAAA;AAAJ","sourcesContent":["\n.fullRound {\n    fill: var(--main-color);\n}\n\n.halfRound {\n    fill: var(--main-background-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"fullRound": `ButtonLoader_fullRound__iG5TG`,
	"halfRound": `ButtonLoader_halfRound__PAeYk`
};
export default ___CSS_LOADER_EXPORT___;
