import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Statuses} from "../../../../shared/enum/statuses";
import {AuthenticationRequestState} from "../types/authenticationTypes";


interface AuthenticationState {
    email: string;
    password: string;
    accessToken: string;
    refreshToken: string;
    status: Statuses;
    error: string;
    errorMessage: string;
}

const initialState: AuthenticationState = {
    email: '',
    password: '',
    accessToken: '',
    refreshToken: '',
    status: Statuses.IDLE,
    error: '',
    errorMessage: ''
}

const authenticationSlice = createSlice({
    name: 'authentication',
    initialState,
    reducers: {
        authenticationRequest: (state, action: PayloadAction<AuthenticationRequestState>) => {
            state.status = Statuses.PENDING;
        },
        authenticationSuccess: (state, action) => {
            state.status = Statuses.SUCCESS;
            state.accessToken = action.payload.accessToken;
            state.refreshToken = action.payload.refreshToken;
        },
        authenticationFailure: (state, action) => {
            state.status = Statuses.FAIL

        },
        setAuthenticationStatus: (state, { payload }) => {
            state.status = payload;
        },
        resetAuthenticationStatus: (state) => {
            state.status = Statuses.IDLE;
        },
        logoutRequest: (state) => {
        },
        logoutSuccess: (state) => {
            state.accessToken = initialState.accessToken;
            state.refreshToken = initialState.refreshToken;
            localStorage.clear();
        },
        logoutFailure: (state) => {
            state.accessToken = initialState.accessToken;
            state.refreshToken = initialState.refreshToken;
            localStorage.clear();
        },
    }
})


export const {
    authenticationRequest,
    authenticationSuccess,
    authenticationFailure,
    resetAuthenticationStatus,
    logoutRequest,
    logoutSuccess,
    logoutFailure
} = authenticationSlice.actions;

export const { reducer: authenticationReducer } = authenticationSlice;

