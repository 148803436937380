import { combineReducers } from "@reduxjs/toolkit";
import {authenticationReducer} from "../../entities/Authentication/model/slice/authenticationSlice";
import {paymentsReducer} from "../../entities/Payments/model/slice/paymentsSlice";
import {chartsReducer} from "../../entities/Charts/model/slice/chartsSlice";

const rootReducer = combineReducers({
    authenticationReducer: authenticationReducer,
    paymentsReducer: paymentsReducer,
    chartsReducer: chartsReducer,

});


export default rootReducer;
