import { Statuses } from '../enum/statuses';

export const useCheckRequestStatus = (status: Statuses) => {
    const isIdle = status === Statuses.IDLE;
    const isPending = status === Statuses.PENDING;
    const isSuccess = status === Statuses.SUCCESS;
    const isFail = status === Statuses.FAIL

    return {
        isIdle,
        isPending,
        isSuccess,
        isFail
    }
};
