// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NavLink_content__7VZUD {
  display: flex;
  align-items: center;
}

.NavLink_pointer__7EY-Z {
  cursor: pointer;
}

.NavLink_boxShadow__S5r2R {
  box-shadow: 8px 8px 20px 0 rgba(83, 88, 235, 0.0392156863);
}

.NavLink_inactive__aNalB {
  color: var(--secondary-font-color);
  background-color: var(--main-background-color);
}

.NavLink_active__q-Bkn {
  background-color: var(--main-color);
  color: var(--main-font-color);
}

.NavLink_text__DTeVD {
  font-size: 16px;
  font-weight: 550;
  line-height: 16px;
  color: var(--font-color);
  margin-left: 10px;
}

.NavLink_icon__FdyAJ path {
  fill: var(--main-icon-color) !important;
}

.NavLink_activeIcon__m2xHc path {
  fill: var(--secondary-icon-color) !important;
}

.NavLink_activeText__goZxu {
  color: var(--main-font-color);
}

.NavLink_container__iEsFS {
  display: inline-block;
  min-width: 232px;
  font-family: HelveticaNeueCyr;
  font-size: 16px;
  font-weight: 550;
  line-height: 16px;
  padding: 15px 20px;
  border-radius: 8px;
}
.NavLink_container__iEsFS:hover {
  background-color: var(--main-color-hover);
}
.NavLink_container__iEsFS:hover .NavLink_text__DTeVD {
  color: var(--main-font-color) !important;
}
.NavLink_container__iEsFS:hover .NavLink_icon__FdyAJ path {
  fill: var(--secondary-icon-color) !important;
}

@media screen and (min-width: 768px) {
  .NavLink_container__iEsFS {
    min-width: 190px;
  }
}`, "",{"version":3,"sources":["webpack://./src/shared/outtalent-ui/NavLink/NavLink.module.scss"],"names":[],"mappings":"AAEA;EACI,aAAA;EACA,mBAAA;AADJ;;AAIA;EACI,eAAA;AADJ;;AAIA;EACI,0DAAA;AADJ;;AAIA;EACI,kCAAA;EACA,8CAAA;AADJ;;AAIA;EACI,mCAAA;EACA,6BAAA;AADJ;;AAIA;EACI,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,wBAAA;EACA,iBAAA;AADJ;;AAKI;EACI,uCAAA;AAFR;;AAOI;EACI,4CAAA;AAJR;;AAQA;EACI,6BAAA;AALJ;;AASA;EACI,qBAAA;EACA,gBAAA;EACA,6BAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;EACA,kBAAA;AANJ;AAQI;EACI,yCAAA;AANR;AAQQ;EACI,wCAAA;AANZ;AASQ;EACI,4CAAA;AAPZ;;AAaA;EACI;IACI,gBAAA;EAVN;AACF","sourcesContent":["\n\n.content {\n    display: flex;\n    align-items: center;\n}\n\n.pointer {\n    cursor: pointer;\n}\n\n.boxShadow {\n    box-shadow: 8px 8px 20px 0 #5358EB0A;\n}\n\n.inactive {\n    color: var(--secondary-font-color);\n    background-color: var(--main-background-color);\n}\n\n.active {\n    background-color: var(--main-color);\n    color: var(--main-font-color);\n}\n\n.text {\n    font-size: 16px;\n    font-weight: 550;\n    line-height: 16px;\n    color: var(--font-color);\n    margin-left: 10px;\n}\n\n.icon {\n    & path {\n        fill: var(--main-icon-color)!important\n    }\n}\n\n.activeIcon {\n    & path {\n        fill: var(--secondary-icon-color)!important\n    }\n}\n\n.activeText {\n    color: var(--main-font-color);\n}\n\n\n.container {\n    display: inline-block;\n    min-width: 232px;\n    font-family: HelveticaNeueCyr;\n    font-size: 16px;\n    font-weight: 550;\n    line-height: 16px;\n    padding: 15px 20px;\n    border-radius: 8px;\n\n    &:hover {\n        background-color: var(--main-color-hover);\n\n        & .text {\n            color: var(--main-font-color)!important;\n        }\n\n        & .icon path {\n            fill: var(--secondary-icon-color)!important;\n        }\n    }\n}\n\n\n@media screen and (min-width: 768px) {\n    .container {\n        min-width: 190px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"content": `NavLink_content__7VZUD`,
	"pointer": `NavLink_pointer__7EY-Z`,
	"boxShadow": `NavLink_boxShadow__S5r2R`,
	"inactive": `NavLink_inactive__aNalB`,
	"active": `NavLink_active__q-Bkn`,
	"text": `NavLink_text__DTeVD`,
	"icon": `NavLink_icon__FdyAJ`,
	"activeIcon": `NavLink_activeIcon__m2xHc`,
	"activeText": `NavLink_activeText__goZxu`,
	"container": `NavLink_container__iEsFS`
};
export default ___CSS_LOADER_EXPORT___;
