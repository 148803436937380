import {$api} from "../../../shared/api/api";
import {URL_AUTHENTICATION} from "../consts/routes";


export class Authentication {
    static async authenticationApi(payload: any): Promise<any> {
        const response = await $api.post(URL_AUTHENTICATION, {
            username: payload.username,
            password: payload.password
        });
        return Authentication.mapAuthenticationSuccessModel(response.data);
    }

    static mapAuthenticationSuccessModel (payload: any) {
        return {
            accessToken:  payload?.jwt_access,
            refreshToken: payload?.jwt_refresh,
        }
    }
}