import {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";

import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";

import {FormControl} from "../../../../shared/outtalent-ui/FormControl/FormControl";
import {Button} from "../../../../shared/outtalent-ui/Button/Button";

import {classNames} from "../../../../shared/lib";

import {authenticationSchema} from "../../shemas/authenticationSchema";

//@ts-ignore
import cls from "./SignInWidget.module.scss";
import {
    authenticationRequest,
    resetAuthenticationStatus
} from "../../../../entities/Authentication/model/slice/authenticationSlice";
// import {Routes} from "../../../../shared/consts/pages";
import {toast} from "react-toastify";
import {defaultValues} from "../../consts/defaultValues";
import {selectAuthenticationStatus} from "../../../../entities/Authentication/model/selectors/authenticationSelectors";
import {useCheckRequestStatus} from "../../../../shared/hooks/useCheckRequestStatus";
import {SeparateText} from "../../../../shared/outtalent-ui/SeparateText/SeparateText";
import {Link, useNavigate} from "react-router-dom";

export const SignInWidget = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const authenticationStatus = useSelector(selectAuthenticationStatus)


    const { isPending } = useCheckRequestStatus(authenticationStatus)

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(authenticationSchema),
        shouldFocusError: false,
        defaultValues
    })

    const notifySuccess = () => toast('Authentication success')
    const notifyError = () => toast('Try Again')


    const onSuccessHandler = () => {

        navigate("/payment/list")
        // if(router.query.next) {
        //     router.push(router.query.next as string).then(() => {
        //         notifySuccess();
        //     })
        //     return;
        // }
        // router.push(Routes.BUY).then(() => {
        //     notifySuccess();
        // })
    }

    const onErrorHandler = () => {
        notifyError();
        dispatch(resetAuthenticationStatus());
        reset();
    }

    const onSubmitHandler = useCallback(async (data: any) => {

        dispatch(authenticationRequest({
            onSuccess: onSuccessHandler,
            onError: onErrorHandler,
            ...data
        }))
    }, [])

    // const goToSignUp = async () => {
    //     await router.push(Routes.SIGN_UP);
    // };

    return (
        <form
            onSubmit={handleSubmit(onSubmitHandler)}
            className={classNames(cls.container, {
                [cls.center]: true
            }, [])}
        >
            <h2 className={cls.title}>
                Welcome to Outtalent
            </h2>
            <FormControl
                className={cls.firstFormControl}
                label="Username"
                errorMessage={errors.username?.message}
                placeholder="Enter your username"
                register={register("username")}
            />
            <FormControl
                className={classNames(cls.secondFormControl, {
                    [cls.secondFormControlError]: Boolean(errors.password?.message)
                }, [])}
                label="Password"
                errorMessage={errors.password?.message}
                placeholder="Enter your password"
                isPassword
                register={register("password")}
            />
            {/*<p className={cls.forgotPassword}><Link href={Routes.FORGOT_PASSWORD}>Forgot password?</Link></p>*/}
            <Button
                type="submit"
                fontWeight={700}
                className={cls.button}
                isLoading={isPending}
                disabled={isPending}
            >
                Sign in
            </Button>
            {/*<SeparateText className={cls.separateText}>*/}
            {/*    Don’t have an account?*/}
            {/*</SeparateText>*/}
            {/*<Button*/}
            {/*    color="reverse"*/}
            {/*    fontWeight={700}*/}
            {/*    className={cls.button}*/}
            {/*    onClick={goToSignUp}*/}
            {/*>*/}
            {/*    Create account*/}
            {/*</Button>*/}
            {/*<div className={cls.terms}>*/}
            {/*    By continuing, you agree with our <a href="https://outtalent.com/privacy"><p>Terms & Conditions</p></a> and <a href="https://outtalent.com/terms"><p>Privacy and Cookie Policy</p></a>*/}
            {/*</div>*/}
        </form>
    );
}
