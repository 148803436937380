export const setLocalState = (key: string, state: any) => {
    try {
        const serializedState = JSON.stringify(state);
        localStorage.setItem(key, serializedState);
    } catch (e) {
        console.log('Could not save state');
    }
}


export const removeLocalStateByKey = (key: string) => {
    try {
        localStorage.removeItem(key);
    } catch (e) {
        console.log(`Could not remove state by key: ${key}`);
    }
};


export const clearLocalState = () => {
    try {
        localStorage.clear();
    } catch (e) {
        console.log('Could not remove state');
    }
};

export const getLocalState = (key: string) => {
    try {
        const serializedState = localStorage.getItem(key);
        if (serializedState === null) {
            return;
        }

        return JSON.parse(serializedState);
    } catch (e) {
        return undefined;
    }
};

export const removeLocalState = (key: string) => {
    try {
        if (!key) return
        localStorage.removeItem(key)
    } catch (e) {
        console.log(e)
    }
}
