import {useEffect, useRef, useState} from "react";
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';
import {MainNavbar} from "../../features/MainNavbar/ui/MainNavbar";
//@ts-ignore
import cls from "./GraphsPage.module.scss";
import {Charts} from "../../entities/Charts/api/Charts";
import {useDispatch, useSelector} from "react-redux";
import {getChartsDataRequest} from "../../entities/Charts/model/slice/chartsSlice";
import {
    selectChartsData,
    selectChartsStatus,
    selectMappedChartsData
} from "../../entities/Charts/model/selectors/chartsSelectors";
import {useCheckRequestStatus} from "../../shared/hooks/useCheckRequestStatus";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import dayjs from "dayjs";

// const data = [
//     {name: 'June', USD: 10000, pv: 2400, amt: 2400},
//     {name: 'July', USD: 20000, pv: 2400, amt: 2400},
//     {name: 'August', USD: 20000, pv: 2400, amt: 2400},
//     {name: 'September', USD: 90000, pv: 2400, amt: 2400},
//     {name: 'October', USD: 70000, pv: 2400, amt: 2400},
//     {name: 'November', USD: 30000, pv: 2400, amt: 2400},
//     {name: 'December', USD: 80000, pv: 2400, amt: 2400},
//     {name: 'January', USD: 100000, pv: 2400, amt: 2400},
//     {name: 'February', USD: 90000, pv: 2400, amt: 2400},
//     {name: 'March', USD: 115000, pv: 2400, amt: 2400},
//     {name: 'April', USD: 100000, pv: 2400, amt: 2400},
//     {name: 'May', USD: 125000, pv: 2400, amt: 2400},
//     {name: 'June', USD: 150000, pv: 2400, amt: 2400},
//     {name: 'July', USD: 180000, pv: 2400, amt: 2400},
//     {name: 'August', USD: 160000, pv: 2400, amt: 2400},
//     {name: 'September', USD: 220000, pv: 2400, amt: 2400},
//     {name: 'October', USD: 250000, pv: 2400, amt: 2400},
// ];


const chartsSchema = yup.object().shape({
    startDate: yup.string(),
    endDate: yup.string()
})

const defaultValues = {
    startDate: dayjs(new Date()).subtract(4, 'month').format("YYYY-MM-DD"),
    endDate: dayjs(new Date()).format("YYYY-MM-DD")
};


export const GraphsPage = () => {
    const ref = useRef<HTMLDivElement>(null);
    const [width, setWidth] = useState(0);

    const dispatch = useDispatch();

    const data = useSelector(selectMappedChartsData);
    const status = useSelector(selectChartsStatus);

    const {
        isSuccess,
    } = useCheckRequestStatus(status);

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(chartsSchema),
        shouldFocusError: false,
        defaultValues
    })

    useEffect(() => {
        dispatch(getChartsDataRequest({
            startDate: dayjs(new Date()).subtract(4, 'month').format("YYYY-MM-DD"),
            endDate: dayjs(new Date()).format("YYYY-MM-DD"),
        }))
    }, []);

    useEffect(() => {
        if(ref?.current) {
            setWidth(ref?.current?.clientWidth - 80)
        }
    }, [ref, isSuccess]);

    return (
        <div className={cls.wrap}>
            <div className={cls.navbarWrap}>
                <MainNavbar />
            </div>

            <h1 className={cls.title}>Graphs</h1>
            <form
                onSubmit={handleSubmit((data) => {
                    dispatch(getChartsDataRequest({
                        startDate: data.startDate,
                        endDate: data.endDate,
                    }))
                })}
                className={cls.dateWrap}
            >
                <div>
                    <input
                        className={cls.dateInput}
                        type="date"
                        {...register("startDate")}
                    />
                </div>
                <div>
                    <input
                        className={cls.dateInput}
                        type="date"
                        {...register("endDate")}

                    />
                </div>
                <div>
                    <button
                        type="submit"
                        className={cls.dateButton}
                    >
                        Apply
                    </button>
                </div>
            </form>
            {isSuccess && (
                <div
                    ref={ref}
                    className={cls.graphs}
                >
                    <LineChart
                        width={width}
                        height={400}
                        data={data}
                        margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
                    >
                        <Line type="monotone" dataKey="USD" stroke="#8884d8" />
                        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                    </LineChart>
                </div>
            )}
        </div>
    )
}