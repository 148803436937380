


export const ClosedEyeIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.929 9C3.119 10.5 2 12 2 12C2 12 6.477 18 12 18C12.685 18 13.354 17.9075 14 17.746M10.016 6.25C10.657 6.091 11.321 6 12 6C17.523 6 22 12 22 12C22 12 20.8805 13.5 19.071 15M10.157 10.3105C9.71487 10.7904 9.47791 11.4242 9.49672 12.0765C9.51553 12.7287 9.78862 13.3478 10.2577 13.8014C10.7267 14.2551 11.3545 14.5073 12.0071 14.5044C12.6596 14.5014 13.2851 14.2434 13.75 13.7855M21 21L3 3"
                stroke="#537EEB"
                // stroke="#47DEB6"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"/>
        </svg>
    )
}
