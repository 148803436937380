import {FC} from "react";
import {ButtonProps} from "./Button.types";
//@ts-ignore
import cls from "./Button.module.scss";
import {classNames} from "../../lib";
import {ButtonLoader} from "../../icons/ButtonLoader/ButtonLoader";

export const Button: FC<ButtonProps> = ({
    disabled = false,
    fontWeight,
    loader,
    isLoading,
    onClick,
    type = "button",
    color = "primary",
    textAlign = "center",
    className,
    fullWidth = false,
    children
}) => {
    return (
        <button
            type={type}
            className={classNames(cls.container, {
                [cls.textStart]: textAlign === "start",
                [cls.textCenter]: textAlign === "center",
                [cls.textEnd]: textAlign === "end",
                [cls.primary]: color === "primary",
                [cls.cancel]: color === "cancel",
                [cls.blue]: color === "blue",
                [cls.yellow]: color === "yellow",
                [cls.light]: color === "light",
                [cls.reverse]: color === "reverse",
                [cls.isLoading]: isLoading,
                [cls.w100]: fullWidth,
            }, [className])}
            onClick={onClick}
            disabled={disabled}
        >
            <div className={cls.innerWrap}>
                {isLoading && (
                    <div className={cls.loader}>
                        <div className={cls.loaderIcon}>
                            {loader ? loader : <ButtonLoader color={color} />}
                        </div>
                    </div>
                )}
                <div className={classNames(cls.text, {
                    [cls.bold]: fontWeight === 700 || fontWeight === "bold",
                    [cls.fontWeight550]: fontWeight === 550,
                }, [])}>
                    { children }
                </div>
            </div>
        </button>
    );
}
