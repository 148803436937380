import {FC} from "react";
import {classNames} from "../../lib";

//@ts-ignore
import cls from './ArrowIcon.module.scss';

export const ArrowIcon: FC<any> = ({
    width = 16,
    height = 16,
    className,
    onClick,
    disabled = false,
    fill = '#537EEB'
}) => {
    return (
        <svg
            className={classNames(className, {
                [cls.disabled]: disabled
            }, [])}
            width={width}
            height={height}
            onClick={onClick}
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5.64645 12.0205C5.45118 11.8253 5.45118 11.5087 5.64645 11.3134L9.29289 7.66699L5.64645 4.02054C5.45118 3.82528 5.45118 3.5087 5.64645 3.31344C5.84171 3.11818 6.15829 3.11818 6.35355 3.31344L10.3536 7.31344C10.5488 7.5087 10.5488 7.82528 10.3536 8.02055L6.35355 12.0205C6.15829 12.2158 5.84171 12.2158 5.64645 12.0205Z"
                fill={fill}
            />
        </svg>

    )
}
