// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PaymentsPage_title__L2CcI {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 40px;
}

.PaymentsPage_wrap__UUEBu {
  padding: 40px 40px;
  background: #f5f5f5;
  height: 100%;
  min-height: 100vh;
}

.PaymentsPage_navbarWrap__Spcsr {
  padding: 0 0 40px 0;
}

.PaymentsPage_table__u6V5h {
  background: #FFFFFF;
  border-radius: 8px;
  padding: 5px 40px;
}

.PaymentsPage_paginationWrap__xgxic {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/pages/PaymentsPage/PaymentsPage.module.scss"],"names":[],"mappings":"AAEA;EACE,eAAA;EACA,iBAAA;EACA,mBAAA;AADF;;AAIA;EACE,kBAAA;EACA,mBAAA;EAEA,YAAA;EACA,iBAAA;AAFF;;AAKA;EACE,mBAAA;AAFF;;AAKA;EACE,mBAAA;EACA,kBAAA;EACA,iBAAA;AAFF;;AAKA;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,gBAAA;EACA,mBAAA;AAFF","sourcesContent":["\n\n.title {\n  font-size: 32px;\n  font-weight: bold;\n  margin-bottom: 40px;\n}\n\n.wrap {\n  padding: 40px 40px;\n  background: #f5f5f5;\n\n  height: 100%;\n  min-height: 100vh;\n}\n\n.navbarWrap {\n  padding: 0 0 40px 0;\n}\n\n.table {\n  background: #FFFFFF;\n  border-radius: 8px;\n  padding: 5px 40px;\n}\n\n.paginationWrap {\n  display: flex;\n  justify-content: center;\n  align-items: center;\n  margin-top: 20px;\n  margin-bottom: 20px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `PaymentsPage_title__L2CcI`,
	"wrap": `PaymentsPage_wrap__UUEBu`,
	"navbarWrap": `PaymentsPage_navbarWrap__Spcsr`,
	"table": `PaymentsPage_table__u6V5h`,
	"paginationWrap": `PaymentsPage_paginationWrap__xgxic`
};
export default ___CSS_LOADER_EXPORT___;
