// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MainNavbar_container__uqCrB {
  display: flex;
  position: relative;
}

.MainNavbar_headerNavbar__B8lDO {
  display: none;
}

.MainNavbar_leftElement__hSzkr {
  position: absolute;
  width: 46px;
  height: 46px;
  left: 0;
  top: 0;
  background: linear-gradient(to left, transparent, var(--secondary-background-color));
}

.MainNavbar_rightElement__C2fGe {
  position: absolute;
  width: 46px;
  height: 46px;
  right: 0;
  top: 0;
  background: linear-gradient(to right, transparent, var(--secondary-background-color));
  margin-right: 0 !important;
}

.MainNavbar_element__fvzml {
  margin-left: auto;
  cursor: pointer;
  display: inline-block;
  font-family: HelveticaNeueCyr;
  font-size: 16px;
  font-weight: 550;
  line-height: 16px;
  padding: 15px 20px;
  border-radius: 8px;
  background-color: var(--main-color);
  color: #FFFFFF;
}
.MainNavbar_element__fvzml:hover {
  background-color: var(--main-color-hover);
}
.MainNavbar_element__fvzml:hover .MainNavbar_text__AmRVF {
  color: var(--main-font-color) !important;
}

@media screen and (min-width: 768px) {
  .MainNavbar_headerNavbar__B8lDO {
    display: flex;
    overflow: scroll;
  }
  .MainNavbar_headerNavbar__B8lDO > a {
    margin-right: 15px;
  }
  .MainNavbar_headerNavbar__B8lDO::-webkit-scrollbar {
    display: none;
  }
}`, "",{"version":3,"sources":["webpack://./src/features/MainNavbar/ui/MainNavbar.module.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,kBAAA;AACJ;;AAEA;EACI,aAAA;AACJ;;AAEA;EACI,kBAAA;EACA,WAAA;EACA,YAAA;EACA,OAAA;EACA,MAAA;EACA,oFAAA;AACJ;;AAEA;EACI,kBAAA;EACA,WAAA;EACA,YAAA;EACA,QAAA;EACA,MAAA;EACA,qFAAA;EACA,0BAAA;AACJ;;AAEA;EACI,iBAAA;EACA,eAAA;EACA,qBAAA;EACA,6BAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;EACA,kBAAA;EACA,mCAAA;EAEA,cAAA;AAAJ;AAEI;EACI,yCAAA;AAAR;AAEQ;EACI,wCAAA;AAAZ;;AAMA;EACI;IACI,aAAA;IACA,gBAAA;EAHN;EAKM;IACI,kBAAA;EAHV;EAMM;IACI,aAAA;EAJV;AACF","sourcesContent":[".container {\n    display: flex;\n    position: relative;\n}\n\n.headerNavbar {\n    display: none;\n}\n\n.leftElement {\n    position: absolute;\n    width: 46px;\n    height: 46px;\n    left: 0;\n    top: 0;\n    background: linear-gradient(to left, transparent,  var(--secondary-background-color));\n}\n\n.rightElement {\n    position: absolute;\n    width: 46px;\n    height: 46px;\n    right: 0;\n    top: 0;\n    background: linear-gradient(to right, transparent,  var(--secondary-background-color));\n    margin-right: 0!important;\n}\n\n.element {\n    margin-left: auto;\n    cursor: pointer;\n    display: inline-block;\n    font-family: HelveticaNeueCyr;\n    font-size: 16px;\n    font-weight: 550;\n    line-height: 16px;\n    padding: 15px 20px;\n    border-radius: 8px;\n    background-color: var(--main-color);\n\n    color: #FFFFFF;\n\n    &:hover {\n        background-color: var(--main-color-hover);\n\n        & .text {\n            color: var(--main-font-color)!important;\n        }\n\n    }\n}\n\n@media screen and (min-width: 768px) {\n    .headerNavbar {\n        display: flex;\n        overflow: scroll;\n\n        & > a {\n            margin-right: 15px;\n        }\n\n        &::-webkit-scrollbar {\n            display: none;\n        }\n    }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `MainNavbar_container__uqCrB`,
	"headerNavbar": `MainNavbar_headerNavbar__B8lDO`,
	"leftElement": `MainNavbar_leftElement__hSzkr`,
	"rightElement": `MainNavbar_rightElement__C2fGe`,
	"element": `MainNavbar_element__fvzml`,
	"text": `MainNavbar_text__AmRVF`
};
export default ___CSS_LOADER_EXPORT___;
