import NavLink from "../../../shared/outtalent-ui/NavLink/NavLink";
import React, {FC, useEffect, useRef, useState} from "react";
//@ts-ignore
import cls from "./MainNavbar.module.scss";
import {CardIcon} from "../../../shared/icons/CartIcon/CardIcon";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {UpcomingSessionIcon} from "../../../shared/icons/UpcomingSessionIcon/UpcomingSessionIcon";
import {CalendarIcon} from "../../../shared/icons/CalendarIcon/CalendarIcon";


export const routes = [
    // {
    //     id: 1,
    //     title: 'Payments',
    //     path: '/payment/list',
    //     icon: <UpcomingSessionIcon />,
    //     expected: [
    //         '/payment/list',
    //     ]
    // },
    // {
    //     id: 2,
    //     title: 'Graphs',
    //     path: '/graphs',
    //     icon: <CalendarIcon />,
    //     expected: [
    //         '/graphs',
    //     ]
    // },
    {
        id: 3,
        title: 'Billings',
        path: '/billings',
        icon: <CalendarIcon />,
        expected: [
            '/billings',
        ]
    },
    // {
    //     id: 3,
    //     title: 'Charts',
    //     path: '/react-charts',
    //     icon: <CalendarIcon />,
    //     expected: [
    //         '/react-charts',
    //     ]
    // },

]


export const MainNavbar: FC<any> = ({ userRole }) => {

    const location = useLocation();

    const firstElementRef = useRef<HTMLDivElement>(null);
    const lastElementRef = useRef<HTMLDivElement>(null);
    const [showLeftElement, setShowLeftElement] = useState(false);
    const [showRightElement, setShowRightElement] = useState(false);

    useEffect(() => {
        const cachedRef: any = firstElementRef.current;
        const observer = new IntersectionObserver(
            ([e]) => {
                setShowLeftElement(!e.isIntersecting)},
            { threshold: [1]}
        );
        observer.observe(cachedRef);

        return () => observer.unobserve(cachedRef);
    }, [firstElementRef]);

    useEffect(() => {
        const cachedRef: any = lastElementRef.current;
        const observer = new IntersectionObserver(
            ([e]) => {
                setShowRightElement(!e.isIntersecting)},
            { threshold: [1]}
        );
        observer.observe(cachedRef);

        return () => observer.unobserve(cachedRef);
    }, [lastElementRef]);

    return (
        <div className={cls.container}>
            <div className={cls.headerNavbar}>
                <div ref={firstElementRef} />
                {showLeftElement ? (
                    <div className={cls.leftElement} />
                ) : null}
                {showRightElement ? (
                    <div className={cls.rightElement} />
                ) : null}

                {routes.map((route) => (
                    <Link
                        key={route.id}
                        to={route.path}
                    >
                        <NavLink
                            key={route.id}
                            active={route.expected?.includes(location.pathname)}
                            icon={route.icon}
                        >
                            {route.title}
                        </NavLink>
                    </Link>
                ))}

                <div ref={lastElementRef} />
            </div>

            <div
                className={cls.element}
                onClick={() => {
                    localStorage.removeItem("user")
                    window.location.reload();
                }}
            >
                Sign Out
            </div>
        </div>
    )
}
