import {createSelector} from "reselect";


const selectChartsSlice = (state: any) => state.chartsReducer;



export const selectCharts = (state: any) => selectChartsSlice(state).charts;
export const selectChartsData = (state: any) => selectCharts(state).data;
export const selectChartsStatus = (state: any) => selectCharts(state).status;


export const selectMappedChartsData = createSelector(selectChartsData, (state) => {
    return state.map((item: any) => ({name: item?.month, USD: item?.total_amount}))
})

