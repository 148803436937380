import {FC} from "react";
import { TableContainer, Table, Tbody, Td, Th, Thead, Tr } from '@chakra-ui/react';
//@ts-ignore
import cls from './DataTable.module.scss';

interface DataTableProps {
    columns: any[],
    data: any[],
}

const DataTable: FC<DataTableProps> = ({ columns, data }) => {

    return (
        <TableContainer
            mt='25px'
            mb='25px'
            whiteSpace='break-spaces'
        >
            <Table variant='simple'>
                <Thead>
                    <Tr>
                        {columns.map((column, index) =>
                            <Th key={index}>{column.title}</Th>
                        )}
                    </Tr>
                </Thead>
                <Tbody>
                    {data && data?.map((obj, dataIndex) => {
                        return (
                            <Tr
                                key={dataIndex}
                            >
                                {columns.map((col, index) => (
                                    <Td key={index}>{col.render(obj)}</Td>
                                ))}
                            </Tr>
                        )
                    })}
                </Tbody>
            </Table>
        </TableContainer>
    );
};

export default DataTable;
