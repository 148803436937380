import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Statuses} from "../../../../shared/enum/statuses";
import {Payments} from "../../api/Payments";


interface PaymentsState {
    payments: {
        data: any[];
        currentPage: number;
        total: number;
        status: Statuses;
        error: string;
        errorMessage: string;
    }
    billings: {
        data: any[];
        status: Statuses;
        error: string;
        errorMessage: string;
    }

}

const initialState: PaymentsState = {
    payments: {
        data: [],
        currentPage: 0,
        total: 0,
        status: Statuses.IDLE,
        error: '',
        errorMessage: ''
    },
    billings: {
        data: [],
        status: Statuses.IDLE,
        error: '',
        errorMessage: ''
    }

}

const paymentsSlice = createSlice({
    name: 'payments',
    initialState,
    reducers: {
        getPaymentsListRequest: (state, { payload }) => {
            state.payments.currentPage = payload.currentPage
            state.payments.status = Statuses.PENDING
        },
        getPaymentsListSuccess: (state, { payload }) => {
            state.payments.data = payload.results
            state.payments.total = Number(payload.count)
            state.payments.status = Statuses.SUCCESS
        },
        getPaymentsListFailure: (state, { payload }) => {
            state.payments.data = []
            state.payments.status = Statuses.FAIL
        },
        getBillingsRequest: (state, { payload }) => {
            state.billings.status = Statuses.PENDING
        },
        getBillingsSuccess: (state, { payload }) => {
            state.billings.data = payload
            state.billings.status = Statuses.SUCCESS
        },
        getBillingsFailure: (state, { payload }) => {
            state.billings.data = []
            state.billings.status = Statuses.FAIL
        }
    }
})


export const {
    getPaymentsListRequest,
    getPaymentsListSuccess,
    getPaymentsListFailure,
    getBillingsRequest,
    getBillingsSuccess,
    getBillingsFailure,
} = paymentsSlice.actions;

export const { reducer: paymentsReducer } = paymentsSlice;

