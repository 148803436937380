import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getPaymentsListRequest} from "../../entities/Payments/model/slice/paymentsSlice";
import {
    selectPaymentsCurrentPage,
    selectPaymentsListData,
    selectPaymentsListStatus, selectPaymentsListTotal
} from "../../entities/Payments/model/selectors/paymentsSelectors";
import DataTable from "../../shared/outtalent-ui/DataTable/DataTable";
//@ts-ignore
import cls from "./PaymentsPage.module.scss"
import {MainNavbar} from "../../features/MainNavbar/ui/MainNavbar";
import dayjs from "dayjs";
import Pagination from "rc-pagination";



const paymentsColumns = [
    {
        title: 'ID',
        render: (rowData: any) => rowData.id
    },
    {
        title: 'Amount USD',
        render: (rowData: any) => rowData?.amount_usd
    },
    {
        title: 'Amount Original Currency',
        render: (rowData: any) => rowData?.amount_original_currency
    },
    {
        title: 'Original Currency',
        render: (rowData: any) => rowData?.original_currency
    },
    {
        title: 'Stripe Fee',
        render: (rowData: any) => rowData?.stripe_fee
    },
    {
        title: 'Payment Status',
        render: (rowData: any) => rowData?.status
    },
    {
        title: 'Customer Email',
        render: (rowData: any) => rowData?.customer_email
    },
    {
        title: 'Created Datetime',
        render: (rowData: any) => dayjs(rowData?.created_datetime).format("DD-MM-YYYY HH:mm")
    },

]


export const PaymentsPage = () => {
    const dispatch = useDispatch();

    const data = useSelector(selectPaymentsListData)
    const status = useSelector(selectPaymentsListStatus)
    const total = useSelector(selectPaymentsListTotal)
    const currentPage = useSelector(selectPaymentsCurrentPage)

    useEffect(() => {
        dispatch(getPaymentsListRequest({
            currentPage: 1
        }))
    }, []);


    return (
        <div className={cls.wrap}>
            <div className={cls.navbarWrap}>
                <MainNavbar />
            </div>

            <h1 className={cls.title}>Payments</h1>
            <div className={cls.table}>
                <DataTable
                    columns={paymentsColumns}
                    data={data}
                />
            </div>

            <div className={cls.paginationWrap}>
                <Pagination
                    className="pagination-data"
                    onChange={(page) => {
                        dispatch(getPaymentsListRequest({
                            currentPage: Number(page)
                        }))
                    }}
                    current={currentPage}
                    total={total}
                    pageSize={10}
                />
            </div>
        </div>
    )
}