// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FormControl_container__x1y5t {
  display: flex;
  flex-direction: column;
}

.FormControl_label__xbnZv {
  color: var(--secondary-font-color);
  font-family: HelveticaNeueCyr;
  font-size: 16px;
  font-weight: 550;
  line-height: 16px;
  margin-bottom: 15px;
}

.FormControl_error__4qKrF {
  margin-top: 10px;
  color: var(--font-error-color);
  font-family: HelveticaNeueCyr;
  font-size: 14px;
  font-weight: 550;
  line-height: 14px;
}

.FormControl_light__7-u4q {
  color: var(--third-font-color);
}`, "",{"version":3,"sources":["webpack://./src/shared/outtalent-ui/FormControl/FormControl.module.scss"],"names":[],"mappings":"AACA;EACI,aAAA;EACA,sBAAA;AAAJ;;AAGA;EACI,kCAAA;EACA,6BAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,mBAAA;AAAJ;;AAGA;EACI,gBAAA;EACA,8BAAA;EACA,6BAAA;EACA,eAAA;EACA,gBAAA;EACA,iBAAA;AAAJ;;AAKA;EACI,8BAAA;AAFJ","sourcesContent":["\n.container {\n    display: flex;\n    flex-direction: column;\n}\n\n.label {\n    color: var(--secondary-font-color);\n    font-family: HelveticaNeueCyr;\n    font-size: 16px;\n    font-weight: 550;\n    line-height: 16px;\n    margin-bottom: 15px;\n}\n\n.error {\n    margin-top: 10px;\n    color: var(--font-error-color);\n    font-family: HelveticaNeueCyr;\n    font-size: 14px;\n    font-weight: 550;\n    line-height: 14px;\n\n}\n\n\n.light {\n    color: var(--third-font-color);\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `FormControl_container__x1y5t`,
	"label": `FormControl_label__xbnZv`,
	"error": `FormControl_error__4qKrF`,
	"light": `FormControl_light__7-u4q`
};
export default ___CSS_LOADER_EXPORT___;
