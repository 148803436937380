import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Statuses} from "../../../../shared/enum/statuses";
import {stat} from "fs";


interface ChartsState {
    charts: {
        data: any[],
        status: Statuses,
        error: null
    }
}

const initialState: ChartsState = {
    charts: {
        data: [],
        status: Statuses.IDLE,
        error: null
    }
}

const chartsSlice = createSlice({
    name: 'charts',
    initialState,
    reducers: {
        getChartsDataRequest: (state, action) => {
            state.charts.status = Statuses.PENDING;
        },
        getChartsDataSuccess: (state, action) => {
            state.charts.status = Statuses.SUCCESS;
            state.charts.data = action.payload;
        },
        getChartsDataFailure: (state) => {
            state.charts.status = Statuses.FAIL;
        }
    }
})


export const {
    getChartsDataRequest,
    getChartsDataSuccess,
    getChartsDataFailure
} = chartsSlice.actions;

export const { reducer: chartsReducer } = chartsSlice;

