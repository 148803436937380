import {Input} from "../Input/Input";
import {FC} from "react";
//@ts-ignore
import cls from "./FormControl.module.scss";
import {PasswordInput} from "../PasswordInput/PasswordInput";
import {classNames} from "../../lib";


export const FormControl: FC<any> = ({
    className,
    isPassword,
    label,
    errorMessage,
    register,
    theme = "primary",
    ...props
}) => {
    return (
        <div className={classNames(cls.container, {}, [className])}>
            {label && <label className={classNames(cls.label, {
                [cls.light]: theme === "light",
            }, [])}>{label}</label>}
            {isPassword ? (
                <PasswordInput
                    register={register}
                    {...props}
                />
            ) : (
                <Input
                    register={register}
                    {...props}
                />
            )}
            {errorMessage && <p className={cls.error}>{errorMessage}</p>}
        </div>
    );
}
