// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.GraphsPage_title__i9bn0 {
  font-size: 32px;
  font-weight: bold;
  margin-bottom: 40px;
}

.GraphsPage_wrap__WNUE2 {
  padding: 40px 40px;
  background: #f5f5f5;
  height: 100%;
  min-height: 100vh;
}

.GraphsPage_navbarWrap__q5iX5 {
  padding: 0 0 40px 0;
}

.GraphsPage_graphs__lpurd {
  background: #FFFFFF;
  border-radius: 8px;
  padding: 40px 40px 0 0;
}

.GraphsPage_dateWrap__lsIzk {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 30px;
}

.GraphsPage_dateInput__y3X1S {
  padding: 10px 10px;
  min-width: 300px;
  width: 100%;
  border-radius: 8px;
}

.GraphsPage_dateButton__4eWjD {
  background: #3F46E4;
  color: #FFFFFF;
  border-radius: 8px;
  padding: 10px 30px;
  font-weight: bold;
  width: 100%;
}
.GraphsPage_dateButton__4eWjD:hover {
  background: #393eb6;
}

@media screen and (min-width: 768px) {
  .GraphsPage_dateWrap__lsIzk {
    display: flex;
    flex-direction: row;
    gap: 10px;
    margin-bottom: 30px;
  }
  .GraphsPage_dateInput__y3X1S {
    padding: 10px 10px;
    min-width: 300px;
    border-radius: 8px;
  }
  .GraphsPage_dateButton__4eWjD {
    background: #3F46E4;
    color: #FFFFFF;
    border-radius: 8px;
    padding: 10px 30px;
    font-weight: bold;
    width: auto;
  }
  .GraphsPage_dateButton__4eWjD:hover {
    background: #393eb6;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/GraphsPage/GraphsPage.module.scss"],"names":[],"mappings":"AAEA;EACE,eAAA;EACA,iBAAA;EACA,mBAAA;AADF;;AAIA;EACE,kBAAA;EACA,mBAAA;EAEA,YAAA;EACA,iBAAA;AAFF;;AAKA;EACE,mBAAA;AAFF;;AAKA;EACE,mBAAA;EACA,kBAAA;EACA,sBAAA;AAFF;;AAKA;EACE,aAAA;EACA,sBAAA;EACA,SAAA;EACA,mBAAA;AAFF;;AAKA;EACE,kBAAA;EACA,gBAAA;EACA,WAAA;EACA,kBAAA;AAFF;;AAKA;EACE,mBAAA;EACA,cAAA;EACA,kBAAA;EACA,kBAAA;EACA,iBAAA;EACA,WAAA;AAFF;AAIE;EACE,mBAAA;AAFJ;;AAQA;EACE;IACE,aAAA;IACA,mBAAA;IACA,SAAA;IACA,mBAAA;EALF;EAQA;IACE,kBAAA;IACA,gBAAA;IACA,kBAAA;EANF;EASA;IACE,mBAAA;IACA,cAAA;IACA,kBAAA;IACA,kBAAA;IACA,iBAAA;IACA,WAAA;EAPF;EASE;IACE,mBAAA;EAPJ;AACF","sourcesContent":["\n\n.title {\n  font-size: 32px;\n  font-weight: bold;\n  margin-bottom: 40px;\n}\n\n.wrap {\n  padding: 40px 40px;\n  background: #f5f5f5;\n\n  height: 100%;\n  min-height: 100vh;\n}\n\n.navbarWrap {\n  padding: 0 0 40px 0;\n}\n\n.graphs {\n  background: #FFFFFF;\n  border-radius: 8px;\n  padding: 40px 40px 0 0;\n}\n\n.dateWrap {\n  display: flex;\n  flex-direction: column;\n  gap: 10px;\n  margin-bottom: 30px;\n}\n\n.dateInput {\n  padding: 10px 10px;\n  min-width: 300px;\n  width: 100%;\n  border-radius: 8px;\n}\n\n.dateButton {\n  background: #3F46E4;\n  color: #FFFFFF;\n  border-radius: 8px;\n  padding: 10px 30px;\n  font-weight: bold;\n  width: 100%;\n\n  &:hover {\n    background: #393eb6;\n\n  }\n}\n\n\n@media screen and (min-width: 768px){\n  .dateWrap {\n    display: flex;\n    flex-direction: row;\n    gap: 10px;\n    margin-bottom: 30px;\n  }\n\n  .dateInput {\n    padding: 10px 10px;\n    min-width: 300px;\n    border-radius: 8px;\n  }\n\n  .dateButton {\n    background: #3F46E4;\n    color: #FFFFFF;\n    border-radius: 8px;\n    padding: 10px 30px;\n    font-weight: bold;\n    width: auto;\n\n    &:hover {\n      background: #393eb6;\n\n    }\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `GraphsPage_title__i9bn0`,
	"wrap": `GraphsPage_wrap__WNUE2`,
	"navbarWrap": `GraphsPage_navbarWrap__q5iX5`,
	"graphs": `GraphsPage_graphs__lpurd`,
	"dateWrap": `GraphsPage_dateWrap__lsIzk`,
	"dateInput": `GraphsPage_dateInput__y3X1S`,
	"dateButton": `GraphsPage_dateButton__4eWjD`
};
export default ___CSS_LOADER_EXPORT___;
