// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AuthenticationPage_container__Kw0wg {
  padding-top: 30px;
  padding-bottom: 100px;
}

@media screen and (min-width: 768px) {
  .AuthenticationPage_container__Kw0wg {
    padding-top: 180px;
    padding-bottom: 120px;
    margin: 0 auto;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/AuthenticationPage/AuthenticationPage.module.scss"],"names":[],"mappings":"AAEA;EACI,iBAAA;EACA,qBAAA;AADJ;;AAOA;EAEI;IACI,kBAAA;IACA,qBAAA;IACA,cAAA;EALN;AACF","sourcesContent":["\n\n.container {\n    padding-top: 30px;\n    padding-bottom: 100px;\n}\n\n\n\n\n@media screen and (min-width: 768px) {\n\n    .container {\n        padding-top: 180px;\n        padding-bottom: 120px;\n        margin: 0 auto;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `AuthenticationPage_container__Kw0wg`
};
export default ___CSS_LOADER_EXPORT___;
