import {Input} from "../Input/Input";
import {FC, useState, MouseEvent} from "react";
import {EyeIcon} from "../../icons/EyeIcon/EyeIcon";
import {ClosedEyeIcon} from "../../icons/ClosedEyeIcon/ClosedEyeIcon";
//@ts-ignore
import cls from "./PasswordInput.module.scss";

export const PasswordInput: FC<any> = ({ register, ...props }) => {
    const [hiddenValue, setHiddenValue] = useState(true);

    const toggleHiddenValue = (event: MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();
        setHiddenValue(prevState => !prevState);
    }

    return (
        <div className={cls.container}>
            <Input
                type={hiddenValue ? "password" : "text"}
                register={register}
                {...props}
            />
            <div
                className={cls.toggle}
                onClick={toggleHiddenValue}
            >
                {hiddenValue ? <EyeIcon /> : <ClosedEyeIcon />}
            </div>
        </div>
    );
}
