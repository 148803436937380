import {useEffect, useRef, useState} from "react";
import {LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip, Area, AreaChart} from 'recharts';
import {MainNavbar} from "../../features/MainNavbar/ui/MainNavbar";
//@ts-ignore
import cls from "./BillingsPage.module.scss";
import {useDispatch, useSelector} from "react-redux";
import {useCheckRequestStatus} from "../../shared/hooks/useCheckRequestStatus";
import {useForm} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as yup from "yup";
import dayjs from "dayjs";
import {
    selectBillingsStatus,
    selectMappedBillingsData
} from "../../entities/Payments/model/selectors/paymentsSelectors";
import {getBillingsRequest} from "../../entities/Payments/model/slice/paymentsSlice";
import {FormControl} from "../../shared/outtalent-ui/FormControl/FormControl";
import {Input} from "../../shared/outtalent-ui/Input/Input";
import {Button} from "../../shared/outtalent-ui/Button/Button";
import {ArrowIcon} from "../../shared/icons/ArrowIcon/ArrowIcon";
import {classNames} from "../../shared/lib";

// const data = [
//     {name: 'June', USD: 10000, pv: 2400, amt: 2400},
//     {name: 'July', USD: 20000, pv: 2400, amt: 2400},
//     {name: 'August', USD: 20000, pv: 2400, amt: 2400},
//     {name: 'September', USD: 90000, pv: 2400, amt: 2400},
//     {name: 'October', USD: 70000, pv: 2400, amt: 2400},
//     {name: 'November', USD: 30000, pv: 2400, amt: 2400},
//     {name: 'December', USD: 80000, pv: 2400, amt: 2400},
//     {name: 'January', USD: 100000, pv: 2400, amt: 2400},
//     {name: 'February', USD: 90000, pv: 2400, amt: 2400},
//     {name: 'March', USD: 115000, pv: 2400, amt: 2400},
//     {name: 'April', USD: 100000, pv: 2400, amt: 2400},
//     {name: 'May', USD: 125000, pv: 2400, amt: 2400},
//     {name: 'June', USD: 150000, pv: 2400, amt: 2400},
//     {name: 'July', USD: 180000, pv: 2400, amt: 2400},
//     {name: 'August', USD: 160000, pv: 2400, amt: 2400},
//     {name: 'September', USD: 220000, pv: 2400, amt: 2400},
//     {name: 'October', USD: 250000, pv: 2400, amt: 2400},
// ];


const chartsSchema = yup.object().shape({
    fellowCount: yup.number(),
    fellowSalary: yup.number(),
    percent: yup.number(),
    month: yup.number(),
    progress: yup.number(),
    startDate: yup.string(),
    endDate: yup.string()
})

const defaultValues = {
    fellowCount: 0,
    fellowSalary: 100000,
    percent: 10,
    month: 24,
    progress: 0,
    startDate: dayjs(new Date()).subtract(48, 'month').format("YYYY-MM-DD"),
    endDate: dayjs(new Date()).add(24, 'month').format("YYYY-MM-DD")
};


export const BillingsPage = () => {
    const ref = useRef<HTMLDivElement>(null);
    const [width, setWidth] = useState(0);
    const [isOpen, setIsOpen] = useState(false);

    const dispatch = useDispatch();

    const data = useSelector(selectMappedBillingsData);
    const status = useSelector(selectBillingsStatus);

    const {
        isSuccess,
    } = useCheckRequestStatus(status);

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors }
    } = useForm({
        resolver: yupResolver(chartsSchema),
        shouldFocusError: false,
        defaultValues
    })

    useEffect(() => {
        dispatch(getBillingsRequest({
            startDate: null,
            endDate: null,
        }))
    }, []);

    useEffect(() => {
        if(ref?.current) {
            setWidth(ref?.current?.clientWidth - 80)
        }
    }, [ref, isSuccess]);

    return (
        <div className={cls.wrap}>
            <div className={cls.navbarWrap}>
                <MainNavbar />
            </div>

            <h1 className={cls.title}>Graphs</h1>
            <div className={cls.formWrap}>
                <div
                    className={cls.formWrapHeader}
                    onClick={() => {
                        setIsOpen(prevState => !prevState)
                    }}
                >
                    <div className={cls.formWrapTitle}>
                        Customize data for predicted billings
                    </div>
                    <div>
                        <ArrowIcon
                            className={classNames(cls.arrow, {
                                [cls.arrowIsOpen]: isOpen
                            }, [])}
                            width={40}
                            height={40}
                        />
                    </div>
                </div>
                {isOpen && (
                    <form
                        onSubmit={handleSubmit((data) => {
                            dispatch(getBillingsRequest({
                                fellowCount: data.fellowCount,
                                fellowSalary: data.fellowSalary,
                                percent: data.percent,
                                month: data.month,
                                progress: data.progress,
                                startDate: data.startDate,
                                endDate: data.endDate,
                            }))
                        })}
                        className={cls.dateWrap}
                    >
                        <div>
                            <FormControl
                                label="Offers signed per month"
                                register={register("fellowCount")}
                            />
                        </div>
                        <div>
                            <FormControl
                                label="Average base salary per fellow, USD"
                                register={register("fellowSalary")}
                            />
                        </div>
                        <div>
                            <FormControl
                                label="Payments duration, months"
                                register={register("month")}
                            />
                        </div>
                        <div>
                            <FormControl
                                label="Percentage of salary fellows owe:"
                                register={register("percent")}
                            />
                        </div>
                        <div>
                            <FormControl
                                label="Monthly growth of offers signed,  %:"
                                register={register("progress")}
                            />
                        </div>
                        <div>
                            <label
                                htmlFor="startDate"
                                className={cls.dateLabel}
                            >
                                Start date:
                            </label>
                            <Input
                                className={cls.dateInput}
                                type="date"
                                register={register("startDate")}
                            />
                        </div>
                        <div>
                            <label
                                htmlFor="endDate"
                                className={cls.dateLabel}
                            >
                                End date:
                            </label>
                            <Input
                                className={cls.dateInput}
                                type="date"
                                register={register("endDate")}
                            />
                        </div>
                        <div className={cls.buttons}>
                            <Button
                                type="submit"
                                fontWeight={700}
                                fullWidth
                            >
                                Apply
                            </Button>
                            <Button
                                color="cancel"
                                fontWeight={700}
                                fullWidth
                                onClick={() => {
                                    reset()
                                    dispatch(getBillingsRequest({
                                        startDate: null,
                                        endDate: null,
                                    }))
                                }}
                            >
                                Cancel
                            </Button>
                        </div>
                    </form>
                )}
            </div>
            {isSuccess && (
                <div
                    ref={ref}
                    className={cls.graphs}
                >
                    <AreaChart
                        width={width}
                        height={400}
                        data={data}
                        margin={{ top: 5, right: 20, bottom: 5, left: 0 }}
                    >
                        <Area type="monotone" dataKey="Predicted billings" stroke="#82ca9d" fill="#82ca9d" />
                        <Area type="monotone" dataKey="Real billings" stroke="#8884d8" fill="#8884d8" />
                        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
                        <XAxis dataKey="name" />
                        <YAxis />
                        <Tooltip />
                    </AreaChart>
                </div>
            )}
        </div>
    )
}