import {createSelector} from "reselect";
import dayjs from "dayjs";


const selectPaymentsSlice = (state: any) => state.paymentsReducer;


export const selectPaymentsList = (state: any) => selectPaymentsSlice(state).payments;
export const selectPaymentsListData = (state: any) => selectPaymentsList(state).data;
export const selectPaymentsListStatus = (state: any) => selectPaymentsList(state).status;
export const selectPaymentsListTotal = (state: any) => selectPaymentsList(state).total;
export const selectPaymentsCurrentPage = (state: any) => selectPaymentsList(state).currentPage;



export const selectBilling = (state: any) => selectPaymentsSlice(state).billings;
export const selectBillingData = (state: any) => selectBilling(state).data;
export const selectBillingsStatus = (state: any) => selectBilling(state).status;


export const selectMappedBillingsData = createSelector(selectBillingData, (state) => {
    return state.map((item: any) => ({ name: item?.month, "Predicted billings": Number(item?.total_sum?.toFixed(2)),  "Real billings": Number(item?.calculate_sum?.toFixed(2)) }))
})





