import {FC} from "react";
import {NavLinkProps} from "../NavLink/NavLink.types";
import {classNames} from "../../lib";
//@ts-ignore
import cls from "./NavLink.module.scss";


const NavLink: FC<NavLinkProps> = ({ onClick, pointer = true, boxShadow = true, icon, active = false, className, children, }) => {
    return (
        <div
            onClick={onClick}
            className={classNames(cls.container, {
                [cls.active]: active,
                [cls.inactive]: !active,
                [cls.boxShadow]: boxShadow,
                [cls.pointer]: pointer,
            }, [className])}
        >
            <div className={cls.content}>
                <div className={classNames(cls.icon, {
                    [cls.activeIcon]: active,
                }, [])}>
                    { icon }
                </div>
                <p className={classNames(cls.text, {
                    [cls.activeText]: active,
                }, [])}>
                    { children }
                </p>
            </div>
        </div>
    )
}

export default NavLink;
