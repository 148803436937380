import { all } from 'redux-saga/effects';
import {watchAuthenticationEffects} from "../../entities/Authentication/model/effects/authenticationEffects";
import {watchPaymentsEffects} from "../../entities/Payments/model/effects/paymentsEffects";
import {watchChartsEffects} from "../../entities/Charts/model/effects/chartsEffects";

export function* rootSaga() {
    yield all([
        watchAuthenticationEffects(),
        watchPaymentsEffects(),
        watchChartsEffects(),
    ]);
}
