import {SagaIterator} from "redux-saga";
import {call, put, takeLatest} from "redux-saga/effects";
import {getChartsDataFailure, getChartsDataRequest, getChartsDataSuccess} from "../slice/chartsSlice";
import {Charts} from "../../api/Charts";


function* getChartsDataEffect ({payload}: any): SagaIterator {
    try {
        const response = yield call(Charts.getChartsApi, payload.startDate, payload.endDate);
        yield put(getChartsDataSuccess(response));
    } catch (error) {
        yield put(getChartsDataFailure());
    }
}

export function * watchChartsEffects (): SagaIterator {
    yield takeLatest(getChartsDataRequest, getChartsDataEffect);

}