import {SagaIterator} from "redux-saga";
import {call, put, takeLatest} from "redux-saga/effects";
import {
    getBillingsFailure,
    getBillingsRequest, getBillingsSuccess,
    getPaymentsListFailure,
    getPaymentsListRequest,
    getPaymentsListSuccess
} from "../slice/paymentsSlice";
import {Payments} from "../../api/Payments";


function* getPaymentsListEffect({ payload }: any): SagaIterator {
    try {
        const response = yield call(Payments.getPaymentListApi, payload.currentPage);
        yield put(getPaymentsListSuccess(response));
    } catch (error) {
        yield put(getPaymentsListFailure(error));
    }
}

function* getBillingsEffect({ payload }: any): SagaIterator {
    try {
        const response = yield call(Payments.getBillingsApi,
            payload.startDate,
            payload.endDate,
            payload.fellowCount,
            payload.fellowSalary,
            payload.percent,
            payload.month,
            payload.progress);
        yield put(getBillingsSuccess(response));
    } catch (error) {
        yield put(getBillingsFailure(error));
    }
}

export function * watchPaymentsEffects (): SagaIterator {
    yield takeLatest(getPaymentsListRequest, getPaymentsListEffect);
    yield takeLatest(getBillingsRequest, getBillingsEffect);
}
