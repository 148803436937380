import {$api} from "../../../shared/api/api";
import {URL_BILLINGS, URL_PAYMENTS_LIST} from "../consts/routes";


export class Payments {
    static async getPaymentListApi(page: any): Promise<any> {
        const response = await $api.get(`${URL_PAYMENTS_LIST}?page=${page}`);
        return response.data;
    }


    static async getBillingsApi(
        startDate: any,
        endDate: any,
        fellowCount: any,
        fellowSalary: any,
        percent: any,
        month: any,
        progress: any
        ): Promise<any> {
        const params = {
            start_date: startDate,
            end_date: endDate,
            fellow_count: fellowCount,
            salary: fellowSalary,
            percent: percent,
            months: month,
            progress_percent: progress
        }

        const response = await $api.get(URL_BILLINGS, {
            params
        });
        return response.data;
    }
}