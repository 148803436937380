import axios, {AxiosError, AxiosResponse} from 'axios';
import TokenService from "../api/token.service";

let isRefreshing = false;
let failedQueue: any = [];

const processQueue = (error: AxiosError | null, token: string): void => {
    failedQueue.forEach((prom: any) => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    failedQueue = [];
};


export const $api = axios.create({
    baseURL: process.env.API_DJANGO_DOMAIN || 'https://test-finance-api.outtalent.com'
    // baseURL: 'http://localhost:8000'
});

export const configureInterceptors = (store: any) => {
    const instances = [$api];

    instances.forEach((axios) => {
        axios.interceptors.request.use((config) => {
            try {

                const accessToken = TokenService.getLocalAccessToken();

                if (Boolean(accessToken)) {
                    config.headers['Authorization'] = `${accessToken}`;
                }
                config.headers['Access-Control-Allow-Origin'] = '*';
                config.headers["Access-Control-Allow-Methods"] = "GET, POST, PUT, OPTIONS, PATCH, DELETE";
                // config.headers["Access-Control-Allow-Headers"] = "Access-Control-Allow-Headers, Origin, Accept, X-Requested-With, Content-Type, Access-Control-Request-Method, Access-Control-Request-Headers, Authorization, Content-Type";
                config.headers["Access-Control-Allow-Headers"] = "accept, accept-encoding, authorization, content-type, origin, dnt, user-agent, x-csrftoken, x-requested-with, Access-Control-Allow-Headers, Access-Control-Allow-Methods, Access-Control-Allow-Origin";


            } catch (error) {
                console.log(error);
            }

            return config;
        })
    })
    instances.forEach((axios) => {
        axios.interceptors.response.use(
            response => response,
            async (error) => {

                const originalConfig = error.config;

                if (originalConfig.url !== "/api/v1/user-login" && error.response) {
                    // Access Token was expired
                    if (error.response.status === 401 && !originalConfig._retry) {
                        originalConfig._retry = true;

                        try {
                            const response = await axios.post("/api/v1/user-jwt-refresh", {
                                jwt_refresh: TokenService.getLocalRefreshToken(),
                            });

                            const accessToken = response.data?.jwt_access;
                            TokenService.updateLocalAccessToken(accessToken);

                            return axios(originalConfig);
                        } catch (_error) {
                            return Promise.reject(_error);
                        }
                    }
                }

                return Promise.reject(error);

                // if (error.response.status === 404) {
                //     window.location.href = '/404'
                // }
                // const originalRequest = error.config;
                // if (
                //     error.response.status === 401 &&
                //     !originalRequest._retry &&
                //     originalRequest.url !== "/api/v1/user-jwt-refresh"
                // ) {
                //     if (isRefreshing) {
                //         return new Promise((resolve, reject) => {
                //             failedQueue.push({ resolve, reject });
                //         })
                //             .then(token => {
                //                 originalRequest.headers.Authorization = token;
                //                 return axios(originalRequest);
                //             })
                //             .catch(err => {
                //                 return Promise.reject(err);
                //             });
                //     }
                //
                //     originalRequest._retry = true;
                //     isRefreshing = true;
                //
                //     return new Promise((resolve, reject) => {
                //         axios
                //             .post("/api/v1/user-jwt-refresh", { jwt_refresh: store.getState().authenticationSlice?.refreshToken })
                //             .then(({ data }: AxiosResponse<{ access_token: string; refresh: string }>) => {
                //
                //                 // store.getState().authenticationSlice?.access_token
                //                 store.setState((state: any) => ({...state, authenticationSlice: {...state.authenticationSlice, accessToken: data.access_token} }))
                //                 axios.defaults.headers.common.Authorization = data.access_token;
                //                 originalRequest.headers.Authorization = data.access_token;
                //                 processQueue(null, data.access_token);
                //                 resolve(axios(originalRequest));
                //             })
                //             .catch(err => {
                //                 processQueue(err, '');
                //
                //                 reject(err);
                //             })
                //             .then(() => {
                //                 isRefreshing = false;
                //             });
                //     });
                // }
                //
                // return Promise.reject(error);
            },
        );
    })
}
