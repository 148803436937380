import {SignInWidget} from "../../widgets/SignInWidget/ui/SignInWidget/SignInWidget";
//@ts-ignore
import cls from "./AuthenticationPage.module.scss"
export const AuthenticationPage = () => {

    return (
        <div className={cls.container}>
            <SignInWidget />
        </div>
    );
}
