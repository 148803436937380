// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Input_input__jZh9B {
  font-size: 16px;
  font-weight: 400;
  line-height: 16px;
  padding: 15px 20px;
  border-radius: 8px;
  border: 1px solid var(--main-input-color);
}
.Input_input__jZh9B:focus {
  border: 1px solid var(--main-active-input-color);
}
.Input_input__jZh9B::placeholder {
  color: var(--third-font-color);
}

.Input_w100__vPKfX {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/shared/outtalent-ui/Input/Input.module.scss"],"names":[],"mappings":"AAEA;EACI,eAAA;EACA,gBAAA;EACA,iBAAA;EACA,kBAAA;EACA,kBAAA;EACA,yCAAA;AADJ;AAGI;EACI,gDAAA;AADR;AAII;EACI,8BAAA;AAFR;;AAOA;EACI,WAAA;AAJJ","sourcesContent":["\n\n.input {\n    font-size: 16px;\n    font-weight: 400;\n    line-height: 16px;\n    padding: 15px 20px;\n    border-radius: 8px;\n    border: 1px solid var(--main-input-color);\n\n    &:focus {\n        border: 1px solid var(--main-active-input-color);\n    }\n\n    &::placeholder {\n        color: var(--third-font-color);\n    }\n}\n\n\n.w100 {\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"input": `Input_input__jZh9B`,
	"w100": `Input_w100__vPKfX`
};
export default ___CSS_LOADER_EXPORT___;
