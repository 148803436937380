import { Navigate } from "react-router-dom";
import {selectUserIsExist} from "../../entities/Authentication/model/selectors/authenticationSelectors";
import {useSelector} from "react-redux";

export const ProtectedRoute = ({ children }: any) => {
    const user = useSelector(selectUserIsExist);


    if (!user) {
        // user is not authenticated
        return <Navigate to="/" />;
    }
    return children;
};