import {SagaIterator} from "redux-saga";
import {call, put, takeLatest} from "redux-saga/effects";
import {Authentication} from "../../api/Authentication";
import {PayloadAction} from "@reduxjs/toolkit";
import {AuthenticationRequestState} from "../types/authenticationTypes";
import {setLocalState} from "../../../../shared/lib/localStorage/localStorage";
import {authenticationFailure, authenticationRequest, authenticationSuccess} from "../slice/authenticationSlice";

function* authenticationEffect({payload: {onSuccess, onError, ...payload}}: PayloadAction<AuthenticationRequestState>): SagaIterator {
    try {
        const response = yield call(Authentication.authenticationApi, payload);
        setLocalState('user', response);
        yield put(authenticationSuccess(response));
        if(Boolean(onSuccess)) onSuccess();
    } catch (error) {
        yield put(authenticationFailure(error));
        if(Boolean(onError)) onError();
    }
}

// function * logoutEffect (): SagaIterator {
//     try {
//         yield call(Authentication.logoutApi);
//         yield put(logoutSuccess());
//     } catch (error) {
//         yield put(logoutFailure());
//     }
// }
export function * watchAuthenticationEffects (): SagaIterator {
    yield takeLatest(authenticationRequest, authenticationEffect);
    // yield takeLatest(logoutRequest, logoutEffect);
}
